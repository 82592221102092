<template>


  <div class="mt-2">
    <div class="card">

      <div class="row align-items-end">
        <div class="text-lg-left text-center ml-4">
          <h4 class="title mb-0" @click="toResume(resume.id)"
             @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
            {{resume.full_name}}
          </h4>

          <ul class="list-inline mb-0 mt-2">
            <li class="list-inline-item mr-2"><small class="text-muted h6 mr-2">{{resume.resume_headline}} -</small></li>
            <li class="list-inline-item">
              <map-pin-icon class="fea icon-sm"></map-pin-icon> <small class="text-muted h6 mr-2"> {{resume.location_city}}</small>
            </li>
            <li class="list-inline-item">
              <small class="text-muted h6 mr-2">- возраст: {{resume.age}} лет</small>
            </li>
            <li class="list-inline-item">
              <small class="text-muted h6 mr-2">- опыт: {{resume.experience_total}} лет</small>
            </li>
            <li class="list-inline-item">
              <small class="text-muted h6 mr-2">- з/п: {{resume.salary}}k ₽</small>
            </li>

          </ul>
          <!--
          <div class="row align-items-end">
            <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0 ml-2">
              <ul class="list-inline mb-0 mt-3">
                <li class="list-inline-item mr-2" v-for="skill in resume.skills">
                  <a href="#" class="btn btn-pills btn-info btn-md mr-2"> {{ skill.skill }} </a>
                </li>
              </ul>
            </div>
          </div>
          -->

          <div class="row align-items-end">
            <div class="col text-md-left text-center mt-4 mt-sm-0 ml-2">
              <ul class="list-inline mb-0 mt-3">
                <li class="list-inline-item mr-2" v-for="(skill,ix) in resume.skills" v-if="ix<5">
                  <a href="#" class="btn btn-pills btn-info btn-md mr-2"> {{ skill.skill }}
                     </a>
                </li>
              </ul>
            </div>
          </div>

        </div>

      </div>

      <div class="row align-items-end">
        <div class="col-lg-12 ml-4 mt-4 pt-2" v-for="(job,ix) in resume.jobs" v-if="ix<3">
          <div class="media">
            <div class="company-logo text-muted h6 mr-3 text-center">
              {{ job.start_date }} - {{ job.end_date_str }}
              <br/>
              {{job.experience_years}} лет
            </div>
            <div class="media-body">
              <h5 class="title mb-0">{{ job.position }}</h5>
              <small class="text-muted company-university"
              >{{ job.company_name }} - {{ job.location_city}}<span v-if="job.location_country!=='Россия'">, {{job.location_country}}</span> </small
              >
            </div>
          </div>
        </div>

      </div>

      <div v-if="$store.state.number_of_vacancies===0" class="row align-items-end mb-2">
        <div class="col-lg-12 ml-4 mt-4 pt-2">
          <small class="text-muted h6 mr-2">Чтобы сохранить данного кандидата, сначала создайте вакансию</small>
        </div>
      </div>



    </div>
    <div class="row list-inline justify-content-between ml-2">

      <a v-if="$store.state.number_of_vacancies===0" class="btn btn-primary"
          @click="$router.push({path:'/new-job-edit'})"> Создать вакансию </a>

      <b-dropdown v-if="$store.state.number_of_vacancies>0" class="list-inline-item btn-group ml-2" variant="primary" text="Сохранить">
        <b-dropdown-item v-for="(vacancy,i) in vacancyNames" @click="saveResumeInVacancy(i)">
          {{ vacancy.job_title }}
        </b-dropdown-item>
      </b-dropdown>


      <div class="list-inline justify-content-start" v-if="resume.vacancies.length>0">
     <h6 class="list-inline-item m2">Резюме сохранено:</h6>
      <a  class="btn btn-secondary list-inline-item m-2" v-for="(vacancy,ix) in resume.vacancies"> {{vacancy.name}}
        <span @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
              class="badge badge-pill badge-secondary" @click="removeResumeFromVacancy(ix)">X</span></a>
      </div>
    </div>
  </div>



</template>

<script>

import { MapPinIcon } from "vue-feather-icons";

import store from "@/store/store";

export default {
  name: "ResumeHightlight",
  components: {
    MapPinIcon,
  },
  props: {
    resume: {
      type: Object,
      required: true,
    },

    vacancyNames: {
      type: Array,
      required: true,
    },

    jobDescription : {
      user_id: 0 ,
      job_id: 0 ,
      job_title: '',
      job_city: '',
      job_type: '',
      job_company: '',
      job_salary_from: 0,
      job_salary_to: 0,
      job_salary_currency: '',
      job_description: '',
      job_status: 'Активная',
      createFlag: 0,

    },

    index: {
      type: Number,
      required: true,
    },

  },
  data() {
    return {
      hover:false,
      saveResume: {
        user_id: 1,
        resume_id:1,
        vacancy_name: '',
        vacancy_id: 1,
        funnel_id:1,
      },

    }
  },
 mounted() {

   if (this.index===0){
     console.log('inside mounted resume');
     console.log(this.vacancyNames);
     console.log(this.resume);
   }
 },
  methods: {

    toResume(x){
      let route = this.$router.resolve({path: '/resume/'+x});
      window.open(route.href, '_blank');
    },

    saveResumeInVacancy(i){



      this.saveResume.resume_id = this.resume.id;
      this.saveResume.user_id = store.state.user_id;

      this.saveResume.vacancy_name = this.vacancyNames[i].job_title;
      this.saveResume.vacancy_id = this.vacancyNames[i].job_id;
      if (this.vacancyNames[i].funnel.length>0) {
        this.saveResume.funnel_id = this.vacancyNames[i].funnel[0].min_funnel_index;
      }
      this.saveResume.resumeHighlight = this.resume;
      let skills_arr = [];
      for (let j = 0; j < this.resume.skills.length; j++) {
        skills_arr.push(this.resume.skills[j].skill);
      }
      this.saveResume.resumeHighlight.skills = skills_arr;

      console.log('inside saveResumeInVacancy');
      console.log(this.saveResume);

      this.axios({
        method: 'post',
        url:
            '' +
            this.$hostname + '/prod/vacancy/save-resume?access_token=' + store.state.token,
        data: this.saveResume,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res) => {
        this.$emit('index', this.index);
      });

    },


    removeResumeFromVacancy(i){
      console.log('inside removeResumeFromVacancy')
      this.saveResume.resume_id = this.resume.id;
      this.saveResume.user_id = store.state.user_id;

        console.log(this.resume.vacancies)

      this.saveResume.vacancy_name = this.resume.vacancies[i].name;
      this.saveResume.vacancy_id = this.resume.vacancies[i].id;


      this.axios({
        method: 'post',
        url:  this.$hostname + '/prod/vacancy/delete-resume?access_token=' + store.state.token,
        data: this.saveResume,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res) => {
        this.$emit('index', this.index);
      });

    },
  }
}
</script>

