<script>
import { ArrowUpIcon, MapPinIcon,PlusIcon,MoreHorizontalIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar0";
//import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import Testimonial from "@/components/testimonial";
import ResumeHighlight from "./ResumeHighlight";
//import ResumeHighlight from "./ResumeHighlightLess";
import SearchLine from "./SearchLine";
import store from "@/store/store";
/**
 * Index-hosting component
 */
export default {

  components: {
    ResumeHighlight,
    Navbar,
    //Switcher,
    Footer,
    ArrowUpIcon,
    MapPinIcon,
    PlusIcon,
    MoreHorizontalIcon,
    Testimonial,
    SearchLine,
  },
  data() {
    return {
      hover: false,

      additionalParamentersMenu: false,

      search_line_string: '',
      andOrFilter_Options : [
        {id:1,value:'Все слова'},
        {id:2,value:'Любое из слов'},
      ],
      andOrFilter_Selected : {id:1,value:'Все слова'},

      whereToSearchFilter_Options : [
        {id:1,value:'Везде'},
        {id:2,value:'Булевый поиск'},
        {id:3,value:'В должностях'},
        {id:4,value:'В названии компании'},
        {id:5,value:'В образовании'},
        {id:6,value:'В навыках'},
        {id:7,value:'Город'},
        {id:8,value:'В ФИО'},
        {id:9,value:'В email'},
      ],
      whereToSearch_Selected : {id:1,value:'Везде'},

      additionalSearchLines: [1,2],
      advancedSearchSwitch: true,

      orderBy_Options : [
        {id:1,value:'По дате добавления резюме'},
        {id:2,value:'По дате обновления резюме'},
        {id:3,value:'По убыванию зарплат'},
        {id:4,value:'По возрастанию зарплат'},
        {id:5,value:'По убыванию возраста'},
        {id:6,value:'По возрастанию возраста'},
      ],
      orderBy_Selected : {id:1,value:'По дате добавления резюме'},

      resumesPerPage_Options : [
        {id:1,value:'10 резюме'},
        {id:2,value:'20 резюме'},
        {id:3,value:'50 резюме'},
        {id:4,value:'100 резюме'},
      ],
      resumesPerPage_Selected : {id:1,value:'10 резюме'},

      list: [{
        id: 1,
        image: 'images/shop/product/s1.jpg',
        title: 'T-Shirt',
        price: 255,
        qty: 2,
        total: 510
      },

      ],

      input_advanced_search: {
        user_id: 1,
        searchParameters: [],
        searchFlags: {},
        page:1,
        resumes_per_page:10,
        hash:'',
      },

      searchLine: '',

      search_parameters: [
        {
        leftParethesis: false,
        field: {name:'Resume headline',id:1,type:'String'},
        relationship:{name:'contains',id:2},
        value:'',
          orButton: false,
          andButton: false,
          rightParethesis:false,
          id:0
        }
        ],

      search_parameters_arr: [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }],

      search_parameters_obj: {
        search_line_string: '',
            andOrFilter_id: 1,
          whereToSearchFilter_id: 1,
      },
      search_parameters_additional: [],

      search_flags: {
        switchEmail: false,
        switchContact: false,

        switchRemote: false,
        switchReadyToMove: false,

        switchHighlighView: false,
        switchResumeView: false,
        switchSaved: false,
      },

      setSQL_ids : {
        ids_arr: [1,2],
        save_search_id: 1,
        user_id: 2,
      },

      savedSearch: {name:'Базовый',id:0,type_id:0},

      resumeIds : [],
      resumes : [],

      total_resume: 5836,

      allVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      activeVacancies: [],
      archiveVacancies: [],

      latestSearches: [],


      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id:0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
      },

      totalSearchResults:1,
      page: {
        active: 1,
        up: 2,
        down: 1,
        max: 3,
        min: 1,
        left_skip: false,
        right_skip: false,
        prev: true,
        next: true,
        skip_number: 3,
      },
      page_arr:[],

      filters: {
        industryFilter: false,
        gradeFilter: false,
        lastActiveFilter: false,
        jobTypeFilter: false,
        experianceFilter: false,
      },


    };
  },
  watch: {
    whereToSearch_Selected (val) {
      //console.log('inside watch whereToSearch_Selected: ' + val);
      this.search_parameters_arr[0].whereToSearchFilter_id  = val.id;
      //console.log(this.search_parameters_arr);
    },
    andOrFilter_Selected (val) {
      //console.log('inside watch andOrFilter_Selected: ' + val);
      this.search_parameters_arr[0].andOrFilter_id  = val.id;
      //console.log(this.search_parameters_arr);
    },
    search_line_string(val) {
      //console.log('inside watch search_line_string: ' + val);
      /*this.search_parameters_obj.search_line_string = val;
      let temp_arr = this.search_parameters_arr;
      this.search_parameters_arr = temp_arr.splice(0,1,this.search_parameters_obj);
      */
      this.search_parameters_arr[0].search_line_string = val;
      //console.log(this.search_parameters_arr);
      //console.log(this.search_parameters_obj);
    }
  },
  mounted() {


    this.loadResumes();

    this.getAllVacancies();
    this.getLatestSearches();
  },

  methods: {

    goToSavedSearchPage(x) {
      let route = this.$router.resolve({path: '/search-candidates/'+x+'/page/1'});
      window.open(route.href, '_self');
    },


    nullSearch() {
      this.search_line_string = '';
      this.andOrFilter_Selected = {id: 1, value: 'Все слова'};
      this.whereToSearch_Selected = {id: 1, value: 'Везде'};
      this.search_parameters_arr = [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }];
      this.search_parameters_additional= [];
    },


    addRemoveLine(x) {
      if (x === -1) {
        this.search_parameters_additional.push(this.search_parameters_obj);
        this.search_parameters_arr.push(this.search_parameters_obj);
      } else {
        this.search_parameters_additional.splice(x, 1);
        this.search_parameters_arr.splice(x + 1, 1);
      }
      console.log('inside addRemoveLine:')
      console.log(this.search_parameters_arr);
    },

    updateParameters(e) {
      console.log('inside updateParameters')
      console.log(e);
      this.addRemoveLine(e);
    },

    update_search_parameters_arr(e) {
      console.log('inside update_search_parameters_arr')
      //console.log(e);

      this.search_parameters_arr[e.index+1].search_line_string = e.search_line_string;
      this.search_parameters_arr[e.index+1].andOrFilter_id = e.andOrFilter_id;
      this.search_parameters_arr[e.index+1].whereToSearchFilter_id = e.whereToSearchFilter_id;
      //this.search_parameters_additional = e.splice(0, 1);
      console.log(this.search_parameters_arr);
    },
/*
    updateResume(e) {
      console.log('inside updateResume')
      console.log(e);

    },
    */

    updateResume(e) {
      console.log('inside updateResume')
      console.log('e = ' + e);

      this.setSQL_ids.ids_arr = [];
      this.setSQL_ids.ids_arr.push(this.resumes[e].id);
      this.setSQL_ids.save_search_id = this.savedSearch.id;
      this.setSQL_ids.user_id = store.state.user_id;
      console.log(this.setSQL_ids)

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/advanced-search/get-resume-byIdList-detail?access_token=' + store.state.token,
        data: this.setSQL_ids,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res_in) => {
        console.log('inside 2nd axios - get resumes')
        console.log(res_in.data);
        if (res_in.data.length) {

          for (var k = 0; k < res_in.data.length; k++) {
            this.resumes.splice(e, 1, res_in.data[k]);
            console.log(' k = ' + k)
          }

        }
      });
    },


    loadResumes() {

      console.log('inside loadResumes')
      /*
      this.inputSqlContacts.searchButtonIsPressed = 1;
      this.inputSqlContacts.step = 1
      */
      this.resumeIds = [];
      this.resumes = [];



      this.input_advanced_search.user_id = store.state.user_id;
      //this.input_advanced_search.searchParameters = this.search_parameters_arr;
      //this.input_advanced_search.searchFlags = this.search_flags;
      this.input_advanced_search.hash = this.$route.params.search_hash;
      this.input_advanced_search.resumes_per_page = 10;
      this.input_advanced_search.page = parseInt(this.$route.params.page);


      /*
      const loading = this.$vs.loading({
        target: this.$refs.resumesListArea,
        scale: 1,
        opacity: 0.1,
      });
      */

      this.$vs.loading({
        container: '#div-with-loading',
        scale: 1,
        opacity: 0.1,
      });

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-naive/get-resumes-per-page?access_token='+ store.state.token,
        data: this.input_advanced_search,
      }).then((res) => {

        if (res.data.status === 'ok') {


          this.totalSearchResults = res.data.total_resumes;


          this.page.max = Math.ceil(res.data.total_resumes / 10);

          this.paginationUtil(parseInt(this.$route.params.page));


          this.setSQL_ids.ids_arr = res.data.resume_ids;

          this.setSQL_ids.save_search_id = this.savedSearch.id;
          this.setSQL_ids.user_id = store.state.user_id;
          console.log(this.setSQL_ids)
          console.log(this.page);
          console.log(this.page_arr);
          var p = JSON.parse(res.data.search_parameters);
          var p1 = p.searchParameters[0]
          this.search_line_string = p1.search_line_string;

          this.whereToSearch_Selected = this.whereToSearchFilter_Options.filter(x => x.id === p1.whereToSearchFilter_id)[0]
          this.andOrFilter_Selected = this.andOrFilter_Options.filter(x => x.id === p1.andOrFilter_id)[0]
          this.search_parameters = p.searchParameters;
          this.search_parameters_arr = p.searchParameters;

          if (p.searchParameters.length>1) {
            //this.search_parameters_additional = p.searchParameters.splice(0,1);
            for (let i = 1; i < p.searchParameters.length; i++) {
              this.search_parameters_additional.push(p.searchParameters[i]);
            }

          }

          console.log('search_parameters_arr');
          console.log(this.search_parameters_arr);
          console.log('search_parameters_additional');
          console.log(this.search_parameters_additional);

          this.search_flags = p.searchFlags;


          if (this.setSQL_ids.ids_arr.length > 0) {
            this.axios({
              method: 'post',
              url: '' + this.$hostname + '/advanced-search/get-resume-byIdList-detail?access_token=' + store.state.token,
              data: this.setSQL_ids,
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }).then((res_in) => {
              console.log('inside 2nd axios - get resumes')

              if (res_in.data.length) {
                for (var k = 0; k < res_in.data.length; k++) {
                  this.resumes.push(res_in.data[k]);
                }
                this.saveResumesInSearch(this.setSQL_ids);
              }
              this.$vs.loading.close("#div-with-loading > .con-vs-loading");
              //loading.close();
            }).catch((error) => {
              this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            });


          }
        }
      });
      //this.saveLatestSearchParameters();


    },

    saveResumesInSearch(setSQL_ids){
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/events/update-was-in-search?access_token=' + store.state.token,
        data: setSQL_ids,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
          .then((res_in) => {
            console.log('inside 2nd axios - events are logged')
          });

    },

    searchCandidates() {
      /*
      this.inputSqlContacts.searchButtonIsPressed = 1;
      this.inputSqlContacts.step = 1
      */
      this.resumeIds = [];
      this.resumes = [];



      this.input_advanced_search.user_id = store.state.user_id;
      this.input_advanced_search.searchParameters = this.search_parameters_arr;
      this.input_advanced_search.searchFlags = this.search_flags;


      console.log('inside searchCandidates')
      /*
      const loading = this.$vs.loading({
        target: this.$refs.resumesListArea,
        scale: 1,
        opacity: 0.1,
      });
        */
      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.5,
        opacity: 0.1,
      });
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-naive/like?access_token=' + store.state.token,
        data: this.input_advanced_search,
      }).then((res) => {
//        console.log(res.data);
        if (res.data.status === 'ok') {
            //this.$router.push({path:'/search-candidates/'+res.data.hash+"/page/"+1});
          let route = this.$router.resolve({path: '/search-candidates/'+res.data.hash+'/page/1'});
          window.open(route.href, '_self');
        }
      }).catch((err) => {
        console.error(err);
        loading.close();
        //this.$router.push('prospect-pool')
      });

      //this.saveLatestSearchParameters();


    },


    searchCandidatesFullText() {
      /*
      this.inputSqlContacts.searchButtonIsPressed = 1;
      this.inputSqlContacts.step = 1
      */
      this.resumeIds = [];
      this.resumes = [];



      this.input_advanced_search.user_id = store.state.user_id;
      this.input_advanced_search.searchParameters = this.search_parameters_arr;
      this.input_advanced_search.searchFlags = this.search_flags;


      console.log('inside searchCandidates')
      /*
      const loading = this.$vs.loading({
        target: this.$refs.resumesListArea,
        scale: 1,
        opacity: 0.1,
      });
        */
      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.5,
        opacity: 0.1,
      });
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-fulltext/like?access_token=' + store.state.token,
        data: this.input_advanced_search,
      }).then((res) => {
//        console.log(res.data);
        if (res.data.status === 'ok') {
          //this.$router.push({path:'/search-candidates/'+res.data.hash+"/page/"+1});
          let route = this.$router.resolve({path: '/search-candidates/'+res.data.hash+'/page/1'});
          window.open(route.href, '_self');
        }
      }).catch((err) => {
        console.error(err);
        loading.close();
        //this.$router.push('prospect-pool')
      });

      //this.saveLatestSearchParameters();


    },


    getAllVacancies(){

      this.input_save_vacancy.user_id = store.state.user_id;

      var url = '' + this.$hostname + '/prod/job-description/list?access_token=' + store.state.token;
      console.log('inside get all jobs ' + this.input_save_vacancy + '  ' + url)

      this.axios({
        method: 'post',
        url: url,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.allVacancies = res.data.arr;
        this.activeVacancies = this.allVacancies.filter(x => x.status === 'Активная');
        this.archiveVacancies = this.allVacancies.filter(x => x.status === 'Архивная');

        console.log(this.allVacancies);
        console.log(this.activeVacancies);
        /*
          if (this.allVacancies.length) {
            this.savedSearch = this.allVacancies[0];
          }
        */
      });

    },


    getAllVacanciesOld() {

      this.input_save_vacancy.user_id = store.state.user_id;

      var url = '' + this.$hostname + '/prod/vacancy/get-list?access_token=' + store.state.token;
      console.log('inside get all jobs ' + this.input_save_vacancy + '  ' + url)

      this.axios({
        method: 'post',
        url: url,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.allVacancies = res.data.arr;
        this.activeVacancies = this.allVacancies.filter(x => x.status === 'Активная');
        this.archiveVacancies = this.allVacancies.filter(x => x.status === 'Архивная');

        console.log(this.allVacancies);
        console.log(this.activeVacancies);
        /*
          if (this.allVacancies.length) {
            this.savedSearch = this.allVacancies[0];
          }
        */
      });
    },

      getLatestSearches() {

        this.input_save_vacancy.user_id = store.state.user_id;


        console.log('inside get all getLatestSearches ')

        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/search-candidates/method-mysql-naive/get-all-searches?access_token=' + store.state.token,
          data: this.input_save_vacancy,
        }).then((res) => {
          this.latestSearches = res.data.latestSearches;
        });

    },

    removeSearch(ix) {

      this.input_save_vacancy.user_id = store.state.user_id;
      this.input_save_vacancy.name = this.latestSearches[ix].hash

      console.log('inside get all removeSearch ')

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-naive/update-searches?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.getLatestSearches();
      });

    },



    paginationUtil(x) {
      this.page.active = parseInt(x);
      this.page.up = Math.min(this.page.active+1, this.page.max);
      this.page.down = Math.max(this.page.active-1, this.page.min);
      if (this.page.active>this.page.min) {
        this.page_arr.push({page: '' + this.page.min, active: 'notactive'});
      }
      if (this.page.active === this.page.min) {
        this.page.prev = false;
      }
      if (this.page.active === this.page.max) {
        this.page.next = false;
      }

      if (this.page.active-this.page.min>=this.page.skip_number) {
        this.page.left_skip = true;
        this.page_arr.push({page:'...',active:'notactive'});
        this.page_arr.push({page:''+this.page.down,active:'notactive'});
        if (this.page.active<this.page.max) {
          this.page_arr.push({page: '' + this.page.active, active: 'active'});
        }
      } else {

        for (var i=2; i<this.page.active; i++) {
          this.page_arr.push({page:''+i,active:'notactive'});
        }
        if (this.page.active<this.page.max) {
          this.page_arr.push({page:''+this.page.active,active:'active'});
        }

      }
      if (this.page.max-this.page.active>=this.page.skip_number) {
        this.page.right_skip = true;
        this.page_arr.push({page:''+this.page.up,active:'notactive'});
        this.page_arr.push({page:'...',active:'notactive'});
        this.page_arr.push({page:''+this.page.max,active:'notactive'});

      } else {
        for (var i = this.page.up; i <= this.page.max; i++) {

          if (this.page.max===i & this.page.active === this.page.max) {
            this.page_arr.push({page: '' + i, active: 'active'});
          } else {
            this.page_arr.push({page: '' + i, active: 'notactive'});
          }
        }

      }
      console.log(this.page_arr);
      console.log(this.page);
    },



    PaginationStep(x){

      if (x===-1) {
        let route = this.$router.resolve({path: '/search-candidates/'+this.$route.params.search_hash+'/page/'+this.page.down});
        window.open(route.href, '_self');
      } else if (x===-2) {
        let route = this.$router.resolve({path: '/search-candidates/'+this.$route.params.search_hash+'/page/'+this.page.up});
        window.open(route.href, '_self');
      } else {
        let route = this.$router.resolve({path: '/search-candidates/'+this.$route.params.search_hash+'/page/'+x});
        window.open(route.href, '_self');
      }

    },




  },


};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />


    <!-- Search Start -->
    <section class="section-two bg-light">
      <div class="container mt-5 vs-con-loading__container" id="div-with-loading">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <form class="p-4 shadow bg-white rounded">
              <div class="d-flex justify-content-between">
              <h4 class="mb-3">Поиск специалиста</h4>
                <div>
                  <a class="btn btn-outline-light btn-sm"
                     v-b-modal.HistoricSearches
                     data-toggle="modal"
                     data-target="#HistoricSearches"> Предыдущие поиски </a>
                  <b-modal id="HistoricSearches" title="Предыдущие поиски" centered>
                    <div class="table-responsive bg-white shadow">
                      <table class="table table-center table-padding mb-0">
                        <thead>
                        <tr>

                          <th class="text-left py-3" >Ключевые слова</th>
                          <th class="text-left py-3" >Дата</th>

                          <th class="text-left py-3" ></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(item, ix) of latestSearches" :key="ix">
                          <!--<td class="text-left" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="$router.push({path:'/search-candidates/'+item.hash+'/page/1'})">
                            {{item.name}}</td>
                            -->
                          <td class="text-left" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="goToSavedSearchPage(item.hash)">
                            {{item.name}}</td>
                          <td class="text-left"> {{item.date}}</td>
                          <td class="h6"><a @click="removeSearch(ix)"
                                            @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                                            class="text-danger">X</a></td>
                        </tr>

                        </tbody>
                      </table>
                    </div>

                  </b-modal>
                  <!--<a href="#" class="btn btn-outline-light btn-sm ml-1"> Сохранить </a>-->
                  <a  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                      @click="nullSearch"
                      class="btn btn-outline-light btn-sm ml-1" > Сбросить </a></div>
              </div>
              <div class="row">
                <div class="col-10 ">

                  <!-- <div class="input-group form-group mb-0">

                       <input
                         name="name"
                         id="name"
                         type="text"
                         class="form-control rounded-left"
                         placeholder="Введите ключевые слова :"
                         v-model="search_parameters[0].value"
                       />

                     <div class="col-2" id="button-addon4">-->
                    <div class="input-group-append form-group">





                      <input
                          name="name"
                          id="name"
                          type="text"
                          class="form-control rounded-left col-6"
                          placeholder="Введите ключевые слова :"
                          v-model="search_line_string"
                      />

                      <select class="form-control custom-select rounded-0" v-model="whereToSearch_Selected">
                        <option v-bind:value="x" v-for="x in whereToSearchFilter_Options">{{x.value}}</option>
                      </select>
                      <select class="form-control custom-select rounded-0" v-model="andOrFilter_Selected">
                        <option v-bind:value="x" v-for="x in andOrFilter_Options">{{x.value}}</option>
                      </select>



                      <input
                          class="searchbtn btn btn-primary btn-block "
                          value="Поиск"

                          @click="searchCandidatesFullText"
                      />

                      </div>
                  <SearchLine v-for="(line,ix) in search_parameters_additional"
                          :index="ix" @index="updateParameters"
                          :search_parameters_arr="search_parameters_arr"
                          @search_parameters_arr="update_search_parameters_arr"
                          />
                </div>
                      <div class="col-2">
                      <a class="btn btn-icon btn-md btn-outline-info btn-pills mr-1" @click="addRemoveLine(-1)"><plus-icon class="fea icon-md"></plus-icon></a>
                      <a class="btn btn-icon btn-md btn-outline-info btn-pills" @click="additionalParamentersMenu=!additionalParamentersMenu">
                        <more-horizontal-icon class="fea icon-md"></more-horizontal-icon></a>

                      </div>
                   <!-- </div> -->
                  </div>

              <div class="widget border-bottom" v-if="additionalParamentersMenu">
                <h4 class="widget-title">Дополнительные параметры поиска</h4>
                <div class="row">
                  <div class="col-2 ">
                  <label >Резюме содержит: </label>
                    <label class="mt-2">Кандидаты: </label>
                    <label class="mt-2">Не показывать: </label>
                  </div>
                  <div class="col-8">

                    <div class="d-flex justify-content-start align-items-center">
                      <div class="form-group mr-4">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchEmail" v-model="search_flags.switchEmail">
                          <label class="custom-control-label" for="switchEmail">Email</label>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchContact" v-model="search_flags.switchContact">
                          <label class="custom-control-label" for="switchContact"> Телефон или Telegram </label>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-start align-items-center">
                      <div class="form-group mr-4">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchRemote" v-model="search_flags.switchRemote">
                          <label class="custom-control-label" for="switchRemote">Готовы работать удаленно</label>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchReadyToMove" v-model="search_flags.switchReadyToMove">
                          <label class="custom-control-label" for="switchReadyToMove">Готовы к переезду</label>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-start align-items-center">
                      <div class="form-group mr-4">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchHighlighView" v-model="search_flags.switchHighlighView">
                          <label class="custom-control-label" for="switchHighlighView">Резюме попали в поиск</label>
                        </div>
                      </div>

                      <div class="form-group mr-4">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchResumeView" v-model="search_flags.switchResumeView">
                          <label class="custom-control-label" for="switchResumeView">Открытые резюме</label>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchSaved" v-model="search_flags.switchSaved">
                          <label class="custom-control-label" for="switchSaved">Сохраненные резюме</label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
                  <!--
                  <vs-switch class="item ml-3" v-model="search_flags.switchEmail"/><label class="item m-1">Email</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchContact"/><label class="item m-1">Контакты</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchRemote"/><label class="item m-1">Удаленка</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchReadyToMove"/><label class="item m-1">Переезд</label>

                </div>


                <div class="col" >
                  <label class="item m-2"><b>Не показывать:</b> </label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchHighlighView"/><label class="item m-1">Ранее попали в поиск</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchResumeView"/><label class="item m-1">Открыты резюме</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchSaved"/><label class="item m-1">Сохранены</label>
                </div>

              </div>
              -->

            </form>
            <!--end form-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>




    <!-- Job List Start -->
    <!--<section class="section">-->
    <div>
      <div class="row m-4">
        <div class="col-lg-2 col-md-6 col-12">
          <input v-if="$store.state.number_of_vacancies>0"
              class="searchbtn btn btn-primary btn-block"
              value="Создать вакансию"
              @click="$router.push({path:'/new-job-edit'})"
          />
        </div>
        <div class="col-lg-8 col-md-6 col-12">
           <h3>Найдено {{ new Intl.NumberFormat().format(totalSearchResults).replace(',', ' ') }} резюме </h3>
          </div>
      </div>
        <div class="row ml-4 mr-4">
          <div class="col-lg-2 col-md-6 col-12">
            </div>
          <!--
          <div class="col-lg-3 col-md-6 col-12">
            <div class="sidebar sticky-bar  p-4 rounded shadow">

              <div class="widget mb-4 pb-2 border-bottom">
                <div class=" d-flex justify-content-between">
                <h5 class="widget-title">Опыт в индустрии</h5>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                  @click="filters.industryFilter=!filters.industryFilter"></plus-icon>
                </div>
                <div class="tagcloud mt-4" v-if="filters.industryFilter">
                  <a href="jvascript:void(0)" class="rounded">Business</a>
                  <a href="jvascript:void(0)" class="rounded">Finance</a>
                  <a href="jvascript:void(0)" class="rounded">Marketing</a>
                  <a href="jvascript:void(0)" class="rounded">Fashion</a>
                  <a href="jvascript:void(0)" class="rounded">Bride</a>
                  <a href="jvascript:void(0)" class="rounded">Lifestyle</a>
                  <a href="jvascript:void(0)" class="rounded">Travel</a>
                  <a href="jvascript:void(0)" class="rounded">Beauty</a>
                  <a href="jvascript:void(0)" class="rounded">Video</a>
                  <a href="jvascript:void(0)" class="rounded">Audio</a>
                </div>
              </div>


              <div class="widget mb-4 pb-4 border-bottom">

                <div class=" d-flex justify-content-between">
                  <h5 class="widget-title">Уровень позиции</h5>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.gradeFilter=!filters.gradeFilter"></plus-icon>
                </div>
                <div class="form-group mt-4 mb-0" v-if="filters.gradeFilter">
                  <select class="form-control custom-select" id="job-catagories">
                    <option>All Jobs</option>
                    <option>Digital & Creative</option>
                    <option>Accountancy</option>
                    <option>Banking</option>
                    <option>IT Contractor</option>
                    <option>Graduate</option>
                    <option>Estate Agency</option>
                  </select>
                </div>
              </div>

              <div class="widget mb-4 pb-4 border-bottom">

                <div class=" d-flex justify-content-between">
                  <h5 class="widget-title">Последняя активность на сайтах</h5>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.lastActiveFilter=!filters.lastActiveFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.lastActiveFilter">
                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="lasthour" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="lasthour">Last Hour</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="oneday" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="oneday">Last 24 hours</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="sevenday" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="sevenday">Last 7 days</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="fourteenday" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="fourteenday">Last 14 days</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-0">
                      <input type="radio" id="onemonth" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="onemonth">Last 30 days</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="widget mb-4 pb-4 border-bottom">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Тип работы</h4>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.jobTypeFilter=!filters.jobTypeFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.jobTypeFilter">
                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="contract">
                      <label class="custom-control-label" for="contract">Contract Base</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="freelancer">
                      <label class="custom-control-label" for="freelancer">Freelancer</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="fulltime">
                      <label class="custom-control-label" for="fulltime">Full Time</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="parttime">
                      <label class="custom-control-label" for="parttime">Part Time</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="remote">
                      <label class="custom-control-label" for="remote">Remote</label>
                    </div>
                  </div>

                  <div class="form-group mb-0">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="intership">
                      <label class="custom-control-label" for="intership">Intership</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="widget">
                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Experience</h4>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.experianceFilter=!filters.experianceFilter"></plus-icon>
                </div>

                <div class="mt-4" v-if="filters.experianceFilter">
                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="freshers" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="freshers">Fresher</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="oneyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="oneyearex">+1 Year</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="twoyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="twoyearex">+2 Year</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="threeyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="threeyearex">+3 Year</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-0">
                      <input type="radio" id="fiveyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="fiveyearex">+5 Year</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          -->

          <div class="col-lg-9 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">

            <div class="row">
              <div class="col-lg-12 col-12 mb-4 pb-2" v-for="(resume,ix) in resumes">
              <div class="widget">
                <ResumeHighlight
                    :resume="resume"
                    :vacancyNames="activeVacancies"
                    @index="updateResume"
                    :index="ix"
                />
              </div>
              </div>
            </div>

            <div class="widget">
              <ul class="pagination justify-content-center mb-0">
                <li class="page-item"
                    @click="PaginationStep(-1)">
                  <a class="page-link" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                     v-if="page.prev" aria-label="Previous" >Пред</a></li>

                <li class="page-item"
                    :class="x.active" v-for="x in page_arr" @click="PaginationStep(x.page)">
                  <a class="page-link" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">{{x.page}}</a></li>

                <li class="page-item"
                    @click="PaginationStep(-2)">
                  <a class="page-link" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                     v-if="page.next" aria-label="Next">След</a></li>
              </ul>
            </div>


          </div>

        </div>

   <!-- </section> -->
    </div>






    <Footer />
    <!--
    <Switcher />
    -->

    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
